import { useState, useRef } from 'react';
import downArrow from '../../assets/images/downArrow.svg';
import cross from '../../assets/images/cross.svg';
import { useEffect } from 'react';

const MultiSelectBox = ({
  options = [],
  placeholder = 'Select options',
  selectedData = [],
  setSelectedData = () => {},
  maxLength,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedData);
  const [customValue, setCustomValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const toggleOption = (option) => {
    const isSelected = selectedOptions.includes(option);

    if (isSelected) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
      setSelectedData(selectedOptions.filter((selected) => selected !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
      setSelectedData([...selectedOptions, option]);
    }
    setCustomValue('');
    // toggleDropdown();
  };

  const removeOption = (option) => {
    setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    setSelectedData(selectedOptions.filter((selected) => selected !== option));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autoCompleteRef.current && !autoCompleteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };

  const addCustomOption = () => {
    if (customValue.trim() !== '') {
      setSelectedOptions([...selectedOptions, customValue.trim()]);
      setSelectedData([...selectedOptions, customValue.trim()]);
      setCustomValue('');
      toggleDropdown();
    }
  };

  useEffect(() => {
    if (customValue) {
      const filteredData = options?.filter((val) =>
        val?.toLowerCase()?.includes(customValue?.toLowerCase()),
      );
      setFilteredOptions(filteredData);
    } else {
      setFilteredOptions(options);
    }
  }, [customValue, options]);

  useEffect(() => {
    setSelectedOptions([...selectedData]);
  }, [selectedData]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const onlyAlphabetsRegex = /^[A-Za-z]+$/;
    if (onlyAlphabetsRegex.test(inputValue) || inputValue === '') {
      setCustomValue(inputValue);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      addCustomOption();
      e.target.blur();
    }
  };

  return (
    <div className="w-full" ref={autoCompleteRef}>
      <div
        className="w-48 bg-white min-w-[330px] relative"
        style={{
          width: '100%',
          background: 'rgb(255, 255, 255)',
        }}
      >
        <div
          className="p-2 flex items-center"
          style={{
            borderRadius: '8px',
            border: '1px solid rgba(161, 160, 160, 0.5)',
          }}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={customValue}
            onChange={(e) => handleInputChange(e)}
            onClick={() => setIsOpen(true)}
            maxLength={maxLength}
            onKeyDown={handleEnterPress}
            className="p-2 flex-grow mr-2"
            style={{
              background: 'rgb(255, 255, 255)',
            }}
          />
          <img src={downArrow} className="cursor-pointer" alt="" onClick={() => setIsOpen(true)} />
        </div>
        {isOpen && (
          <div
            className="shadow-lg absolute z-10 mt-2 min-w-full max-h-[200px] overflow-y-auto w-48 bg-white"
            style={{
              borderRadius: '8px',
              border: '1px solid rgba(161, 160, 160, 0.5)',
            }}
          >
            {filteredOptions?.map((option, index) => (
              <div
                key={option + index}
                onClick={() => toggleOption(option)}
                className={`py-2 px-4 cursor-pointer text capitalize mb-[2px] ${
                  selectedOptions.includes(option) ? 'bg-[#E9F6FF]' : ''
                }`}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedOptions.length > 0 ? (
        <div className="flex flex-wrap mt-1 mb-2 max-h-[130px] overflow-y-auto">
          {selectedOptions.map((option) => (
            <div
              key={option}
              className="flex items-center bg-[#0071BC0D] rounded-md p-1 m-1 capitalize"
            >
              <span className="mr-1 break-all">{option}</span>
              <button
                type="button"
                onClick={() => removeOption(option)}
                className="flex-shrink-0 h-4 w-4 text-red-600 cursor-pointer"
              >
                <img src={cross} alt="" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default MultiSelectBox;
