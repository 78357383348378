import React, { useState, useEffect } from 'react';
import {
  SearchCarrers,
  SearchExperience,
  SearchInterest,
  SearchSkills,
  searchEducation,
} from '../../services/filterService';
import CrossIcon from '../Icons/Cross';
import SearchIcon from '../Icons/SearchIcon';

const FilterSearch = ({
  value = '',
  onChange = () => {},
  placeholder = 'Search',
  onBlur = () => {},
  onFocus = () => {},
  disabled = false,
  iconColor = '#A1A0A0',
  isAutoFocus = false,
  onClear = () => {},
  selectedSearch,
  onElementClick = () => {},
}) => {
  const [searchResults, setSearchResults] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const debouncedFetch = debounce(async () => {
        try {
          let data;
          switch (selectedSearch.toLowerCase()) {
            case 'career':
              data = await SearchCarrers({ search: value });
              break;
            case 'experience':
              data = await SearchExperience({ search: value });
              break;
            case 'education':
              data = await searchEducation({ search: value });
              break;
            case 'skill':
              data = await SearchSkills({ search: value });
              break;
            case 'interest':
              data = await SearchInterest({ search: value });
              break;
            default:
              break;
          }
          setSearchResults(data?.data?.data || {});
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }, 300);

      debouncedFetch();
    };

    fetchData();
  }, [value, selectedSearch]);

  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearchCross = () => {
    onClear();
  };

  const handleElementClick = (element, selectedSearch) => {
    onElementClick(element, selectedSearch);
    onChange('');
  };

  const searchKeyMap = {
    career: 'careers',
    experience: 'experiences',
    education: 'educations',
    skill: 'skills',
    interest: 'interests',
  };

  const searchKey = searchKeyMap[selectedSearch.toLowerCase()] || '';
  const results = searchResults?.[searchKey] || [];

  return (
    <div className="flex flex-col relative">
      <div className="flex relative gap-0 bg-[#F2F2F2] border-0 h-[32px] items-center">
        <div className="flex items-center px-[5px]">
          <SearchIcon color={iconColor} />
        </div>
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          className="text-black font-montserrat text-base font-normal text-[14px] bg-[#F2F2F2] w-[300px] h-[32px] md:w-[200px]"
          disabled={disabled}
          autoFocus={isAutoFocus}
        />
        <div className="cursor-pointer" onClick={handleSearchCross}>
          <CrossIcon fill={iconColor} />
        </div>
      </div>

      {value.length > 0 && (
        <div className="max-h-[100px] bg-[#F2F2F2] mt-2 absolute top-7 z-50 w-full pt-1 pl-2 overflow-y-auto">
          {loading ? (
            <div>Loading...</div>
          ) : results.length > 0 ? (
            <div className="flex flex-col">
              {results.map((element, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() =>
                    handleElementClick(
                      element.title || element.degree || element.skill,
                      selectedSearch,
                    )
                  }
                >
                  {(element.title || element.degree || element.skill).length > 26
                    ? `${(element.title || element.degree || element.skill).slice(0, 26)}...`
                    : element.title || element.degree || element.skill}
                </div>
              ))}
            </div>
          ) : (
            <div
              className="cursor-pointer text-gray-500"
              onClick={() => handleElementClick(value, selectedSearch)}
            >
              {`Add new '${value}'`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSearch;
