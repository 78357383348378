import React, { useState } from 'react';
import InputLinkImage from '../../assets/images/link-input.svg';
import { LANG } from '../../constants/lang';
import { REGEX } from '../../constants/constants';
import { ToastNotifyError } from '../Toast/ToastNotify';
import { TOASTMESSAGES } from '../../constants/messages';
import AddBlueBgIcon from '../Icons/AddBlueBgIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import editIcon from '../../assets/images/editIcon.svg';
import checkIcon from '../../assets/images/check.png';

const { LANG_HTTPS } = LANG.PAGES.CREATE_POST;
const { LINK_PATTERN } = REGEX;
const {
  errorToast: { TST_INVALID_LINKS = '' },
  toastid: { TST_LINK_VALIDATION_FAILED_ID },
} = TOASTMESSAGES;

const CreatePostLinkLayout = ({
  links = [],
  setLinks,
  linkInInput = '',
  setLinkInInput = () => {},
  isInputLinkOpen = false,
  setEnableEditing = () => null,
  enableEditing = false,
}) => {
  const [currentEditingData, setCurrentEditingData] = useState(null);
  // Adding link, checking if the current link value is apt
  const addLink = () => {
    let link = linkInInput;

    if (!link.startsWith('https://')) {
      link = `https://${link}`;
    }

    if (!LINK_PATTERN.test(link)) {
      ToastNotifyError(TST_INVALID_LINKS, TST_LINK_VALIDATION_FAILED_ID);
      return;
    }

    if (links.includes(link)) {
      ToastNotifyError('This link is already added.', TST_LINK_VALIDATION_FAILED_ID);
      return;
    }

    let allLinks = [link, ...links];

    if (allLinks?.length === 5) {
      return;
    }

    setLinks(allLinks);
    setLinkInInput('');
  };

  /**
   * In the UI, we dont have to show https:// as it is in the UI of input box
   * @param {*} link
   * @returns
   */
  const linkValueViewFormat = (link) => {
    if (link?.startsWith('https://')) {
      // Remove "https://"
      link = link?.slice(8);
    }

    return link;
  };

  const handleRemoveLink = (currentIndex) => {
    const updatedLinks = links.filter((item, _i) => item && _i !== currentIndex);
    setLinks(updatedLinks);
  };

  const handleEditLink = (currentIndex) => {
    setCurrentEditingData({
      link: links[currentIndex],
      index: currentIndex,
    });

    setEnableEditing(true);
  };

  const onEditLinkDone = (index) => {
    if (!currentEditingData?.link) return;

    let updatedLink = currentEditingData.link;

    if (!updatedLink.startsWith('https://')) {
      updatedLink = `https://${updatedLink}`;
    }

    if (!LINK_PATTERN.test(updatedLink)) {
      ToastNotifyError(TST_INVALID_LINKS, TST_LINK_VALIDATION_FAILED_ID);
      return;
    }

    if (links.some((link, _i) => link === updatedLink && _i !== index)) {
      ToastNotifyError('This link already exists.', TST_LINK_VALIDATION_FAILED_ID);
      return;
    }

    const updatedLinks = links.map((item, _i) => (_i === index ? updatedLink : item));

    setLinks(updatedLinks);
    setEnableEditing(false);
    setCurrentEditingData(null);
  };

  return (
    <>
      <div className="flex flex-col mb-3">
        {enableEditing ? (
          <div className="flex gap-2 items-center">
            <div className="flex rounded-md border border-greymedium w-[94%]">
              <span className="px-4 inline-flex items-center min-w-fit rounded-l-[7px] border-r-0 text-sm bg-whitelight">
                <img src={InputLinkImage} />
              </span>
              <div className="relative w-full">
                <input
                  type="text"
                  id="hs-inline-add-on"
                  name="hs-inline-add-on"
                  className="py-3 px-4 pl-[68px] block w-full border-gray-200 rounded-md text-sm"
                  value={linkValueViewFormat(currentEditingData?.link)}
                  onChange={(e) =>
                    setCurrentEditingData((prev) => ({
                      ...prev,
                      link: e.target.value,
                    }))
                  }
                />

                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none z-20 pl-4 text-greylight">
                  <span className="text-sm text-gray-500">{LANG_HTTPS}</span>
                </div>
              </div>
            </div>

            <div
              onClick={() => onEditLinkDone(currentEditingData?.index)}
              className={'w-[6%] flex justify-end'}
            >
              <img src={checkIcon} alt="check" className="ml-0 md:ml-[20px] cursor-pointer" />
            </div>
          </div>
        ) : isInputLinkOpen ? (
          <div className="flex gap-2 items-center">
            <div className="flex rounded-md border border-greymedium w-[94%]">
              <span className="px-4 inline-flex items-center min-w-fit rounded-l-[7px] border-r-0 text-sm bg-whitelight">
                <img src={InputLinkImage} />
              </span>
              <div className="relative w-full">
                <input
                  type="text"
                  id="hs-inline-add-on"
                  name="hs-inline-add-on"
                  className="py-3 px-4 pl-[68px] block w-full border-gray-200 rounded-md text-sm"
                  value={linkValueViewFormat(linkInInput)}
                  onChange={(e) => setLinkInInput(e.target.value)}
                />
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none z-20 pl-4 text-greylight">
                  <span className="text-sm text-gray-500">{LANG_HTTPS}</span>
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                if (links.length < 4) {
                  addLink();
                }
              }}
              className={`w-[6%] flex justify-end ${
                links.length === 5 || (isInputLinkOpen && links.length === 4)
                  ? 'opacity-50 cursor-not-allowed'
                  : 'text-blueprimary cursor-pointer'
              }`}
            >
              <AddBlueBgIcon />
            </div>
          </div>
        ) : null}
        <p className="text-xs text-greylight mt-1">Click in the field to add link (Max 5)</p>

        {links?.map((link, _i) => {
          return (
            <div className="relative" key={_i}>
              {enableEditing && currentEditingData?.index !== _i && (
                <div className="absolute top-0 bg-[#ffffff55] w-full h-full"></div>
              )}

              <div className="flex items-center w-full justify-between gap-[4px] hover:bg-whitelighter">
                <div className="flex gap-1 text-blueprimary text-sm mt-4">
                  <img src={InputLinkImage} />
                  <a href={link} className="break-all">
                    {link}
                  </a>
                </div>

                <div className="flex gap-[8px] items-center">
                  <div
                    onClick={() => {
                      if (!enableEditing) handleEditLink(_i);
                    }}
                    className={enableEditing ? '!cursor-not-allowed' : 'cursor-pointer'}
                  >
                    <img src={editIcon} alt="edit" />
                  </div>

                  <div
                    onClick={() => {
                      if (!enableEditing) handleRemoveLink(_i);
                    }}
                    className={enableEditing ? 'cursor-not-allowed' : 'cursor-pointer'}
                  >
                    <RemoveIcon />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CreatePostLinkLayout;
