import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CloseIcon } from '../Icons/CloseIcon';

const MultiSelectDropdown = ({
  isRequired = false,
  label = 'Select',
  initialOptions,
  selectedOptions = [],
  setSelectedOptions,
}) => {
  const scrollRef = useRef(null);
  const outsideRef = useRef(null);

  const [options, setOptions] = useState(initialOptions);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const memoizedOptions = useMemo(() => initialOptions, [initialOptions]);

  useEffect(() => {
    setOptions(memoizedOptions);
  }, [memoizedOptions]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
    }
  }, [selectedOptions]);

  const handleSelect = (option) => {
    if (!selectedOptions.some((selected) => selected.value === option.value)) {
      setSelectedOptions([...selectedOptions, option]);
      setSearchTerm('');
      setIsOpen(false);
    }
  };

  const handleRemove = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item.value !== option.value));
  };

  // Click-away listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Filtered options based on input
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleCreateNew = () => {
    const newOption = { label: searchTerm?.trim(), value: searchTerm?.trim() };
    setOptions([...options, newOption]);
    setSelectedOptions([...selectedOptions, newOption]);
    setSearchTerm('');
    setIsOpen(false);
  };

  return (
    <div className="w-full">
      <div className="flex gap-[2px]">
        <label className="text-[#333333]">{label}</label>
        {isRequired ? <span className="text-red relative top-[-4px]">*</span> : ''}
      </div>
      <div
        className="relative py-[10px] px-[12px] border border-customGray rounded-[8px] w-full"
        ref={outsideRef}
      >
        <div
          className="flex p-1 gap-2 overflow-x-auto items-center w-full"
          style={{
            scrollbarWidth: 'none',
          }}
          ref={scrollRef}
        >
          {selectedOptions.map((option) => (
            <div
              key={option.value}
              className="inline-flex items-center gap-[4px] max-h-[21px] h-fit px-[8px] py-[2px] bg-[#D9D9D9] rounded-[4px]"
            >
              <span className="text-[12px] w-max leading-[17px]">{option.label}</span>
              <CloseIcon
                className={'min-w-[16px] w-[16px] h-[16px] cursor-pointer'}
                onClick={() => handleRemove(option)}
              />
            </div>
          ))}

          <input
            className="flex-1 min-w-[100px] p-0"
            placeholder="Career title"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsOpen(true);
            }}
            onClick={() => setIsOpen(true)}
          />
        </div>

        {isOpen && (
          <div className="absolute top-[54px] left-[0px] w-full max-h-40 overflow-y-auto bg-white shadow-lg z-10">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`p-2 text-[12px] leading-[17px] mb-1 ${
                    selectedOptions.some((selected) => selected.value === option.value)
                      ? 'text-gray-400 cursor-not-allowed bg-[#DEF2FF]'
                      : 'hover:bg-[#3282b8ab] cursor-pointer'
                  }`}
                  onClick={() =>
                    !selectedOptions.some((selected) => selected.value === option.value) &&
                    handleSelect(option)
                  }
                >
                  {option.label}
                </div>
              ))
            ) : searchTerm ? (
              <div
                className="p-2 text-[12px] leading-[17px] hover:bg-[#3282b8ab] cursor-pointer"
                onClick={handleCreateNew}
              >
                Create {`"${searchTerm}"`}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
